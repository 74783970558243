import React from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { buildImageUrl } from '../../utils';
import SectionTitle from '../SectionTitle';

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const RegistrySection = ({ data }) => {
  const [open, setOpen] = React.useState(false);

  if (!data) {
    return '';
  }

  const registry = data.wedding_registry;

  if (registry?.length === 0) {
    return '';
  }

  const slides = [];
  const portfolios = [];
  registry?.forEach((item, _index) => {
    slides.push({
      src: buildImageUrl(item.image.id),
    });
    portfolios.push({
      link: item.link,
      Pimg: buildImageUrl(item.image.id),
    });
  });

  return (
    <section
      className={`wpo-portfolio-section section-padding pt-10`}
      id="gifts">
      <div className=""></div>
      <SectionTitle
        subTitle={'Wedding Registry'}
        MainTitle={'Gifts & Appreciation'}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="portfolio-grids gallery-container clearfix portfolio-slide">
              {portfolios.map((image, i) => (
                <div className="grid" key={i}>
                  <div
                    className="img-holder"
                    onClick={() =>
                      image.link
                        ? window.open(image.link, '_blank')
                        : setOpen(true)
                    }>
                    <img
                      src={image.Pimg}
                      alt=""
                      className="img img-responsive"
                    />
                    <div className="hover-content">
                      <i className="ti-plus"></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-12">
              <div className="text-section text-center">
                <h2>The couple would appreciate cash gifts.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Lightbox open={open} close={() => setOpen(false)} slides={slides} />
    </section>
  );
};

export default RegistrySection;
