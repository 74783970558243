import React from 'react';

// stylesheet
import styles from './loader.module.css';

const Loader = ({ activity }) => {
  return (
    <div className={styles.loader}>
      {activity !== undefined ? <h3>{activity}</h3> : null}

      <div className="preloader">
        <div className="loader-circle">
          <img src="preloader.gif" alt="" className="img img-responsive" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
